import { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { categoryDisplayValue } from "../../../utils/stats";


class MatchupTable extends Component {
    render() {
        const { categoryWinners, overallScore, totals } = this.props.matchup;
        const categories = this.props.game.config.categories
        var headerCols = []
        var yourCols = []
        var oppCols = []
        headerCols.push(<Col className="matchupDataEntry noBorder" key={0} md={1}></Col>)
        for (var i = 0; i < categories.length; i++) {
            var cat = categories[i]
            headerCols.push(<Col className="matchupDataEntry" key={i + 1} md={1}>{cat}</Col>)
        }


        yourCols.push(<Col className="matchupDataEntry" key={0} md={1}>{this.props.game.users[this.props.userId].displayName}</Col>)
        for (i = 0; i < categories.length; i++) {
            cat = categories[i]
            var val = categoryDisplayValue(cat, totals[this.props.userId][cat])

            var className = "matchupDataEntry"
            if (categoryWinners[cat] === this.props.userId) {
                className += " wonCategory"
            }
            else if (categoryWinners[cat] === this.props.opponent) {
                className += " lostCategory"
            }
            else {
                className += " tiedCategory"
            }

            yourCols.push(<Col className={className} key={i + 1} md={1}>{val}</Col>)
        }
        yourCols.push(<Col className="matchupDataEntry" key={-1} md={1}>{overallScore[this.props.userId]}</Col>)

        oppCols.push(<Col className="matchupDataEntry" key={0} md={1}>{this.props.game.users[this.props.opponent].displayName}</Col>)
        for (i = 0; i < categories.length; i++) {
            cat = categories[i]
            val = categoryDisplayValue(cat, totals[this.props.opponent][cat])

            className = "matchupDataEntry"
            if (categoryWinners[cat] === this.props.opponent) {
                className += " wonCategory"
            }
            else if (categoryWinners[cat] === this.props.userId) {
                className += " lostCategory"
            }
            else {
                className += " tiedCategory"
            }

            oppCols.push(<Col className={className} key={i + 1} md={1}>{val}</Col>)
        }
        oppCols.push(<Col className="matchupDataEntry" key={-1} md={1}>{overallScore[this.props.opponent]}</Col>)

        return <Container className="matchupContainer">
            <Row>
                <Col>
                    {`${this.props.game.users[this.props.userId].displayName} vs  ${this.props.game.users[this.props.opponent].displayName}`}
                </Col>
            </Row>
            <Container className="matchupTableContainer">
                <Row className="matchupHeaders">
                    {headerCols}
                </Row>
                <Row className="matchupDataRows">
                    {yourCols}
                </Row>
                <Row className="matchupDataRows">
                    {oppCols}
                </Row>
            </Container>
        </Container>
    }
}

export default class MatchupTables extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const userStats = this.props.stats[this.props.userId]
        const matchups = userStats.matchups
        const opponents = Object.keys(matchups)

        var tables = []
        for (var i = 0; i < opponents.length; i++) {
            tables.push(
                <MatchupTable key={i} {...this.props}
                    matchup={userStats.matchups[opponents[i]]}
                    userId={this.props.userId} opponent={opponents[i]} />
            )
        }

        const date = this.props.initialGame ? this.props.initialGame.config.date : null
        return <>
            <Row>
                <Row>
                    <h2> {`${date}: ${this.props.game.users[this.props.userId].displayName}`} </h2>
                </Row>
                <Row className="matchupSummary">
                    <Col md = {1}></Col>
                    <Col>
                        <Row>
                            <h5>{`Wins: ${userStats.overallStats.totalWins.length}`}</h5>
                        </Row>
                        {
                            userStats.overallStats.totalWins.map(id => {
                                return <Row key={id} className="matchupSummaryCol">
                                    {this.props.game.users[id].displayName}
                                </Row>
                            }
                            )
                        }
                    </Col>
                    <Col>
                        <Row>
                            <h5>{`Losses: ${userStats.overallStats.totalLosses.length}`}</h5>
                        </Row>
                        {
                            userStats.overallStats.totalLosses.map(id =>
                                <Row key={id} className="matchupSummaryCol">
                                    {this.props.game.users[id].displayName}
                                </Row>
                            )
                        }
                    </Col>
                    <Col>
                        <Row>
                            <h5>{`Ties: ${userStats.overallStats.totalTies.length}`}</h5>
                        </Row>

                        {
                            userStats.overallStats.totalTies.map(id =>
                                <Row key={id} className="matchupSummaryCol">
                                    {this.props.game.users[id].displayName}
                                </Row>
                            )
                        }
                    </Col>
                    <Col md = {1}></Col>
                </Row>
            </Row>
            <Row>

                <h2>Matchups</h2>
                {tables}
            </Row>
        </>
    }
}
