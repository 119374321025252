import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getAuth } from "firebase/auth";


export default class FirebaseAuthManager {
    constructor(auth) {
        this.auth = auth
    }

    getCurrentAuthUser(){
        var user = getAuth().currentUser
        if(user){
            return {
                photoURL: user.photoURL,
                displayName: user.displayName,
                email: user.email,
                uid:user.uid,
            }
        }
        return null
    }

    doSignOut() {
        this.auth.signOut();
    }

    async doCreateUserWithGoogleAuth() {
        const provider = new GoogleAuthProvider();
        const auth = this.auth
        await signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                // const token = credential.accessToken;
                // The signed-in user info.
                // const user = result.user;
                // IdP data available using getAdditionalUserInfo(result)
                // ...
            }).catch((error) => {
                // // Handle Errors here.
                // const errorCode = error.code;
                // const errorMessage = error.message;
                // // The email of the user's account used.
                // const email = error.customData.email;
                // // The AuthCredential type that was used.
                // const credential = GoogleAuthProvider.credentialFromError(error);
                // // ...
            });
    }
}