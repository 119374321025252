import { useNavigate, useParams, useLocation } from 'react-router';

export const withRouter = (Component) =>{
    const Wrapper = (props) =>{
        const navigate = useNavigate();
        const location = useLocation();
        const routerParams = useParams();
        return <Component navigate={navigate} location = {location} params = {routerParams} {...props}/>
    } 
    return Wrapper;
}