import "firebase/auth";
import { setDoc, getDoc, doc, collection, updateDoc, deleteField } from "firebase/firestore";
import { nanoid } from 'nanoid'

class FirebaseGameManager {
    constructor(db) {
        this.db = db
    }

    generateEmptyTeamFromConfig(){
        var team =  {centers:{}, utils:{}, forwards:{}, guards:{}};
        return team;
    }

    async addGame(owner, config) {
        var newId = config.date + nanoid();
        const myCollection = collection(this.db, 'games');
        const emptyTeam = this.generateEmptyTeamFromConfig()
        const myDocumentData = {
            "users": { [owner.uid]: { ...owner, owner: true, team:  emptyTeam} },
            "config": config,
            "id": newId
        };

        const myDocRef = doc(myCollection, newId);

        // Add or update the document
        await setDoc(myDocRef, myDocumentData, {merge:true});

        return newId
    }

    async addUserToGame(gameId, user){
        const docRef = doc(this.db, "games", gameId);
        var key = "users." + user.uid

        await updateDoc(docRef, {
            [key]:{ ...user, owner: false, team: this.generateEmptyTeamFromConfig() } 
        });
    }

    async addPlayerToTeam(gameId, userId, playerId, position, positionIndex) {
        // Atomically add a new region to the "regions" array field.
        var key = "users." + userId + ".team." + position + "." + positionIndex

        
        const docRef = doc(this.db, "games", gameId);

        await updateDoc(docRef, {
            [key]:playerId
        });
    }

    async removePlayerFromTeam(gameId, userId, position, positionIndex) {
        // Atomically add a new region to the "regions" array field.
        var key = "users." + userId + ".team." + position + "." + positionIndex

        
        const docRef = doc(this.db, "games", gameId);

        await updateDoc(docRef, {
            [key]:deleteField()
        });
    }

    async getGame(gameId) {
        const docRef = doc(this.db, "games", gameId);
        return await getDoc(docRef)
    }
}

export default FirebaseGameManager;