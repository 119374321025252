import './App.css';
import {
    BrowserRouter as Router,
    Route,
    Routes,
} from 'react-router-dom';
import Navigation from '../Navigation';
import LandingPage from '../Landing';
import GamesPage from '../GamesPage';
import AccountPage from '../AccountPage';
import * as ROUTES from '../../constants/routes';
import { Component } from 'react';
import GamePage from '../GamePage';
import { withRouter } from '../WithRouter';
import { withAuthentication } from '../Session';

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            authUser: null,
        };
    }

    componentDidMount() {
        var prevVersion = localStorage.getItem("REACT_APP_VERSION")
        console.log(`prevVersion: ${prevVersion}, currentVersion:${process.env.REACT_APP_VERSION}`)
        if(prevVersion && prevVersion !== process.env.REACT_APP_VERSION){
            console.log("previous version does not match new version, reset localStorage")
            localStorage.clear()
            localStorage.setItem("REACT_APP_VERSION", process.env.REACT_APP_VERSION)

        } else if(!prevVersion){ // no previous version, assume first time on new version and reset local storage
            console.log("no previous version found, reset localStorage")
            localStorage.clear()
            localStorage.setItem("REACT_APP_VERSION", process.env.REACT_APP_VERSION)
        }
        this.listener = this.props.firebase.auth.onAuthStateChanged(
            authUser => {
                authUser
                    ? this.setState({ authUser })
                    : this.setState({ authUser: null });
            },
        );
    }

    componentWillUnmount() {
        this.listener();
    }

    render() {
        return (
            <div className="App">
                <Router>
                    <Navigation />
                    <Routes className = "routedContent">
                        <Route path={ROUTES.LANDING} Component={LandingPage} />
                        <Route path={ROUTES.GAMES} Component={GamesPage} />
                        <Route path="games/:id" Component={withRouter(GamePage)} />
                        <Route path={ROUTES.ACCOUNT} Component={AccountPage} />
                    </Routes>
                </Router>
            </div>
        );
    }

}

export default withAuthentication(App);
