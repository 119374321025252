import { Component } from "react";
import { withRouter } from "../WithRouter";
import {withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { Button } from "react-bootstrap";

class SignInButtonBase extends Component {
    onSubmit = async event => {
        await this.props.firebase.authManager.doCreateUserWithGoogleAuth()
        var currentUser = this.props.firebase.authManager.getCurrentAuthUser()
        await this.props.firebase.userManager.addUser(currentUser)
        var redirectPath = new URLSearchParams(this.props.location.search).get("redirect")
        if(redirectPath){
            this.props.navigate(redirectPath)
            this.props.navigate(0)
        }else{
            this.props.navigate(ROUTES.GAMES)
            this.props.navigate(0)
        }
    }

    render() {
        return <Button onClick={this.onSubmit}>{this.props.text ? this.props.text : "Sign In"}</Button>
    }
}

const SignInButton = withFirebase(withRouter(SignInButtonBase))
export default SignInButton;
