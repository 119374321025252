import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NewGameModal from "../GamesPage/NewGame"

import { AuthUserContext } from '../Session';
import SignInButton from '../SignInButton';

import * as ROUTES from '../../constants/routes';
import SignOutButton from '../SignOutButton';
import './Navigation.css'
import Offcanvas from 'react-bootstrap/Offcanvas';

const Navigation = () => (
    <AuthUserContext.Consumer>
        {authUser =>
            authUser ? <NavigationAuth /> : <NavigationNonAuth />
        }
    </AuthUserContext.Consumer>
);

class NavigationNonAuth extends Component {
    render() {
        return (
            <Navbar className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand href="/">NBA Daily Fantasy</Navbar.Brand>
                    <Nav>
                        <SignInButton />
                    </Nav>
                </Container>
            </Navbar>
        );
    }
}
class NavigationAuth extends Component {

    render() {
        var expand = "md"
        return (
            <>
                <Navbar key={expand} expand={expand} className="bg-body-tertiary">
                    <Container>
                        <Navbar.Brand href="/">NBA Daily Fantasy</Navbar.Brand>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                        >
                            <Offcanvas.Body>
                                <Nav className="me-auto">
                                    <Nav.Link href={ROUTES.GAMES}>Games</Nav.Link>
                                    <NewGameModal />

                                </Nav>
                                <Nav>
                                    <Nav.Link href={ROUTES.ACCOUNT}>Account</Nav.Link>
                                    <SignOutButton />
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>

            </>
        );
    }
}

export default Navigation;