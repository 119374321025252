import { Component } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import * as ROUTES from '../../constants/routes';
import { withAuthorization } from '../Session';
import { withFirebase } from "../Firebase";
import { withRouter } from "../WithRouter";
import './GamesPage.css';
import * as Encoding from '../../utils/encoding';
import moment from "moment";

class GamesPageBase extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    async componentDidMount() {
        this.componentDidUpdate({}, {})
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.user && this.state.user) {
            return
        }
        var authUser = await this.props.firebase.authManager.getCurrentAuthUser();
        if (authUser) {
            var user = await this.props.firebase.userManager.getUser(authUser.uid);
            this.setState({ user: user.data() })
        }
    }

    onGameClick = async event => {
        this.props.navigate(ROUTES.GAMES + "/" + event.target.value)
    }

    render() {
        var gamesList = null;

        if (this.state.user) {
            var games = this.state.user.games
            if (!games) {
                games = []
            }

            games.sort((a, b) => {
                const d1 = moment(a, 'YYYY-MM-DD')
                const d2 = moment(b, 'YYYY-MM-DD')
                return d2 - d1
            })
            gamesList = games.map((x) =>
                <Row key={x} className="gamesListTableRow">
                    <Col className="gamesListDateEntry gamesListTableRowTextEntry">
                        {Encoding.getDateFromGameId(x)}
                    </Col>
                    <Col className="goCodeContainer gamesListTableRowTextEntry">
                        {x}
                    </Col>
                    <Col className="goButtonContainer">
                        <Button value={x} onClick={this.onGameClick}>go</Button>
                    </Col>
                </Row>
            );
            gamesList.unshift(<Row key={-1}>
                <Col className="gamesListDateEntry">
                    <h2> Date</h2>
                </Col>
                <Col className="goCodeContainer">
                    <h2> Game Code</h2>
                </Col>
                <Col className="goButtonContainer">
                </Col>
            </Row>)
        }
        return <div>
            <Container>
                <Row>
                    <Col>
                    </Col>
                </Row>
                <Row>
                    <Col><h1>Your Games</h1></Col>
                </Row>
                {gamesList}
            </Container>
        </div>
    }
}

const condition = authUser => !!authUser;
const GamesPage = withAuthorization(condition)(withFirebase(withRouter(GamesPageBase)))
export default GamesPage;