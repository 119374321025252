import * as CATEGORIES from "../constants/categories";


export function categoryWinner(category, val1, val2) {
    var res = categoryWinnerHelper(category, val1, val2);

    switch (category) {
        case CATEGORIES.TURNOVERS:
            return -1 * res;
        default:
            return res
    }
}

function categoryWinnerHelper(category, val1, val2) {
    switch (category) {
        case CATEGORIES.FT_PCT:
        case CATEGORIES.FG_PCT:
            return compareFractions(val1, val2);
        default:
            if (val1 > val2) {
                return 1
            } else if (val1 < val2) {
                return -1
            } else {
                return 0
            }
    }
}

// given f1=a/b and f2=c/d  (e.g. 2/3, 3/4)
// f1 > f2 if d*a > b*c
// f1 < f2 if d*a < b*c
// f1 = f2 if d*a = b*c
export function compareFractions(f1, f2) {
    var a = f1[0]
    var b = f1[1]
    var c = f2[0]
    var d = f2[1]
    if (a === 0 && b === 0 && c === 0 && d === 0) {
        return 0
    }
    if (a === 0 && b === 0) {
        return -1
    }
    if (c === 0 && d === 0) {
        return 1
    }

    if (d * a > b * c) {
        return 1
    }
    else if (d * a < b * c) {
        return -1
    }
    return 0
}

export function categoryZeroValue(category) {
    switch (category) {
        case CATEGORIES.FG_PCT:
        case CATEGORIES.FT_PCT:
            return [0, 0]
        default:
            return 0
    }
}

export function categorySum(category, val1, val2) {
    switch (category) {
        case CATEGORIES.FG_PCT:
        case CATEGORIES.FT_PCT:
            return [val1[0] + val2[0], val1[1] + val2[1]]
        default:
            return val1 + val2
    }
}

export function categoryDisplayValue(category, val, game) {
    switch (category) {
        case CATEGORIES.COST:
            return `$${val}`
        case CATEGORIES.FT_PCT:
        case CATEGORIES.FG_PCT:
            if (val[1] === 0){
                return `${val[0]}/${val[1]} (0.000)`
            }
            return val[0] + "/" + val[1] + " (" + (val[0] / val[1]).toFixed(3) + ")"
        default:
            return val
    }
}

export function calculateLeagueStats(game, players) {
    var matchups = calculateMatchups(game, players);
    var res = {};
    var allUsers = Object.keys(matchups)
    var standings = allUsers.map(function (uid) {
        return {
            ...matchups[uid].overallStats,
            uid: uid
        };
    })

    res.standings = sortStandings(standings).reverse()
    return res;
}

function sortStandings(standings) {
    //sort ascending first
    standings.sort(function (a, b) {
        if (a.totalWins.length !== b.totalWins.length) {
            // return negative if a has more wins
            // this means a will appear earlier (smaller)
            return b.totalWins.length - a.totalWins.length
        }
        // if same wins, check losses
        // return positive if a has more losses
        // this means a will be bigger and appear later in the list
        return a.totalLosses.length - b.totalLosses.length
    });

    var prev = "";
    var rank = 1;
    for (var i = 0; i < standings.length; i++) {
        var encode = standings[i].totalWins + "-" + standings[i].totalLosses + "-" + standings[i].totalTies
        if (prev) {
            if (prev !== encode) {
                rank++
            }
        }
        standings[i].rank = rank
        prev = encode
    }
    return standings.reverse();
}

export function calculateMatchups(game, players) {
    var res = {};
    var statsTotals = calculateStatsTotals(game, players)
    const categories = game.config.categories;

    var teamIds = Object.keys(statsTotals)
    for (var i = 0; i < teamIds.length; i++) {
        var team1 = teamIds[i]
        res[team1] = {}
        res[team1].matchups = {}
        res[team1].overallStats = {}
        var totalWins = [];
        var totalLosses = [];
        var totalTies = [];
        for (var j = 0; j < teamIds.length; j++) {
            var team2 = teamIds[j]
            if (i === j) {
                continue;
            }

            res[team1].matchups[team2] = {}
            res[team1].matchups[team2].totals = {
                [team1]: statsTotals[team1],
                [team2]: statsTotals[team2]
            }


            res[team1].matchups[team2].categoryWinners = {}
            var team1Wins = 0
            var team2Wins = 0
            for (var k = 0; k < categories.length; k++) {
                var cat = categories[k]
                var value1 = statsTotals[team1][cat]
                var value2 = statsTotals[team2][cat]

                var winner = categoryWinner(cat, value1, value2)
                if (winner > 0) {
                    res[team1].matchups[team2].categoryWinners[cat] = team1
                    team1Wins++;
                }
                else if (winner < 0) {
                    res[team1].matchups[team2].categoryWinners[cat] = team2
                    team2Wins++;
                }
                else {
                    res[team1].matchups[team2].categoryWinners[cat] = null
                }
            }
            if (team1Wins > team2Wins) {
                res[team1].matchups[team2].overallWinner = team1
                totalWins.push(team2);
            }
            else if (team1Wins < team2Wins) {
                res[team1].matchups[team2].overallWinner = team2
                totalLosses.push(team2);
            }
            else {
                totalTies.push(team2);
            }
            res[team1].matchups[team2].overallScore = {
                [team1]: team1Wins,
                [team2]: team2Wins
            }

        }

        res[team1].overallStats.totalWins = totalWins
        res[team1].overallStats.totalLosses = totalLosses
        res[team1].overallStats.totalTies = totalTies
    }
    return res
}

export function calculateStatsTotals(game, players) {
    var res = {};
    const teamIds = Object.keys(game.users);
    var categories = game.config.categories;
    if(!categories.includes(CATEGORIES.COST)){
        categories = [CATEGORIES.COST].concat(categories)
    }

    for (var i = 0; i < teamIds.length; i++) {
        var teamId = teamIds[i];
        var user = game.users[teamId]
        var team = user.team

        var allPlayersOnTeamIds = [Object.values(team.centers), Object.values(team.forwards), Object.values(team.guards), Object.values(team.utils)].flat()

        allPlayersOnTeamIds = allPlayersOnTeamIds.map(x => players[x])

        for (var j = 0; j < categories.length; j++) {
            var cat = categories[j];
            if (!res[teamId]) {
                res[teamId] = {}
            }
            if (!res[teamId][cat]) {
                res[teamId][cat] = categoryZeroValue(cat)
            }

            for (var k = 0; k < allPlayersOnTeamIds.length; k++) {
                var teamPlayer = allPlayersOnTeamIds[k]
                var val = extractValueFromStatistics(cat, teamPlayer.statistics)

                var sum = categorySum(cat, res[teamId][cat], val)
                res[teamId][cat] = sum
            }
        }

    }
    return res
}

export function extractValueFromStatistics(category, statistics) {
    if (!(category && statistics)) {
        switch (category) {
            case CATEGORIES.FT_PCT:
            case CATEGORIES.FG_PCT:
                return [0, 0]
            default:
                return 0
        }
    }
    var val = categoryZeroValue(category);
    switch (category) {
        case CATEGORIES.POINTS:
            val = statistics.points;
            break;
        case CATEGORIES.ASSISTS:
            val = statistics.assists;
            break;
        case CATEGORIES.REBOUNDS:
            val = statistics.rebounds;
            break;
        case CATEGORIES.STEALS:
            val = statistics.steals;
            break;
        case CATEGORIES.BLOCKS:
            val = statistics.blocks;
            break;
        case CATEGORIES.THREEPM:
            val = statistics.threePointsMade;
            break;
        case CATEGORIES.TURNOVERS:
            val = statistics.turnovers;
            break;
        case CATEGORIES.PLUSMINUS:
            val = statistics.plusMinus;
            break;
        case CATEGORIES.FG_PCT:
            val = [statistics.fieldGoalsMade, statistics.fieldGoalAttempts];
            break;
        case CATEGORIES.FT_PCT:
            val = [statistics.freeThrowsMade, statistics.freeThrowAttempts];
            break;
        case CATEGORIES.COST:
            val = statistics.cost;
            break;
        default:
            val = categoryZeroValue(category);
    }
    if(!val || (val.constructor === Array && val.includes(undefined))){
        return categoryZeroValue(category);
    }
    return val;
}