import React, { Component } from 'react';
import * as ROUTES from '../../constants/routes';
import bgImage from './jumbotronbackground.jpg';

import { withFirebase } from '../Firebase';
import { withRouter } from '../WithRouter';
import './Landing.css'
import { Container, Col, Row } from 'react-bootstrap';
import SignInButton from '../SignInButton';

class LandingPage extends Component {
    async componentDidUpdate() {
        var authUser = await this.props.firebase.authManager.getCurrentAuthUser();
        if (authUser) {
            this.props.navigate(ROUTES.GAMES)
        }
    }
    render() {
        return <div className="landing-jumbotron" style={{ background: `linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)),url(${bgImage})`, backgroundSize: 'cover' }}>
            <Container>
                <Row className="justify-content-md-center">
                    <Col md="6" className="jumbotron-popup">
                        <Row className="justify-content-center">
                            <h1>Welcome to NBA Daily Fantasy!</h1>
                        </Row>
                        <Row className="justify-content-center">
                            <p>
                                Select your lineup for the day and go head-to-head against friends
                            </p>
                            <p>
                                Stats are pulled live from each day's games
                            </p>
                            <p>
                                Login with Google to play
                            </p>
                        </Row>
                        <Row className="justify-content-center">
                            <Col>
                            <SignInButton text = {"Play"}/>
                            </Col>
                        </Row></Col>
                </Row>
            </Container>
        </div>
    }
}


export default withRouter(withFirebase(LandingPage));