import { Component } from "react";
import { Dropdown, Row, Col } from "react-bootstrap";
import Team from "./Team";

export default class Teams extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedUserId: this.props.user.uid
        }
    }

    gridDropdownSelected = (e) => {
        this.setState({ selectedUserId: e.target.getAttribute('data') })
    }

    render() {
        var usersInGame = Object.keys(this.props.game.users)
        var dropDownOptions = usersInGame.map(x =>
            <Dropdown.Item data={x} key={x} onClick={this.gridDropdownSelected}>
                {(x === this.props.user.uid) ? "Your team" : `${this.props.game.users[x].displayName}'s team`}
            </Dropdown.Item>)

        var dropDown = <Dropdown className = "selectTeamDropdown">
            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                Select Team to View
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {dropDownOptions}
            </Dropdown.Menu>
        </Dropdown>

        return <div className = "teamTabContent">
            <Row>
                <Col>{dropDown}</Col>
                
            </Row>
            <Team {...this.props} userId={this.state.selectedUserId} />
        </div>
    }
}
