import { Component } from "react";
import {Button} from "react-bootstrap";
import * as ROUTES from '../../constants/routes';
export default class NotYetInGame extends Component {
    
    onYesClick = async event => {
        await this.props.firebase.gameManager.addUserToGame(this.props.params.id, this.props.user, this.props.initialGame.config)
        await this.props.firebase.userManager.addGame(this.props.user.uid, this.props.params.id)
        this.props.navigate(0)
    }
    onNoClick = async event => {
        this.props.navigate(ROUTES.GAMES)
    }

    render() {
        return  <>
        do you want to join {this.props.params.id}

        <Button variant="primary" onClick={this.onYesClick}>yes</Button>{' '}
        <Button variant="secondary" onClick={this.onNoClick}>no</Button>{' '}
      </>
    }
}
