import { Component } from "react";
import { withAuthorization } from '../Session';

import AlreadyInGame from "./AlreadyInGame"
import GameNotFound from "./GameNotFound"
import NotYetInGame from "./NotYetInGame"


class GamePage extends Component {
    constructor(props) {
        super(props)
        this.state = ({})
    }

    async componentDidMount() {
        var game = (await this.props.firebase.gameManager.getGame(this.props.params.id)).data();
        var currentUser = await this.props.firebase.authManager.getCurrentAuthUser();
        this.setState({ game: game, user: currentUser })
    }


    render() {
        var newProps = {...this.props, "initialGame":this.state.game,  "user":this.state.user}
        if(!this.state.game){
            return <GameNotFound {...newProps} />
        }

        if(!Object.keys(this.state.game.users).includes(this.state.user.uid) ){
            return <NotYetInGame {...newProps}/>
        }

        return <AlreadyInGame {...newProps}/>
    }
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(GamePage);