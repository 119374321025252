import { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { calculateLeagueStats } from "../../../utils/stats";
import './League.css'

export default class League extends Component {
    constructor(props) {
        super(props)
        this.leagueStats = calculateLeagueStats(this.props.game, this.props.players)
    }

    componentDidUpdate() {
        this.leagueStats = calculateLeagueStats(this.props.game, this.props.players)
    }

    render() {
        var standings = this.leagueStats.standings;
        var standingRows = standings.map(x =>
            <Row className = "standingsDataRow" key = {x.uid}>
                <Col className = "standingsDataEntry">{x.rank} </Col>
                <Col className = "standingsDataEntry">{this.props.game.users[x.uid].displayName} </Col>
                <Col className = "standingsDataEntry">{x.totalWins.length + "-" + x.totalLosses.length + "-" + x.totalTies.length} </Col>
            </Row>)


        return <Container>
            <h2>Standings</h2>
            <Row>
                <Col className = "standingsDataRow"><h5>Rank</h5> </Col>
                <Col className = "standingsDataRow"><h5>Team</h5> </Col>
                <Col className = "standingsDataRow"><h5>Record</h5>  </Col>
            </Row>
            {standingRows}
        </Container>
    }
}
