

import { Component } from 'react';
import Button from 'react-bootstrap/Button';
import { withRouter } from '../../WithRouter';
import { withFirebase } from "../../Firebase";

class RemovePlayer extends Component {
    
    onClick = async event => {
        var gameId = this.props.params.id
        var position = this.props.player.position
        var positionIndex = this.props.player.positionIndex
        var userId = this.props.user.uid
        this.props.firebase.gameManager.removePlayerFromTeam(gameId, userId, position, positionIndex)
    }


    render() {
        return (
            <>
                <Button variant="danger" disabled = {this.props.disabled} onClick={this.onClick}>
                    -
                </Button>
            </>
        );
    }
}

export default withRouter(withFirebase(RemovePlayer));