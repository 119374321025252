export const POINTS = 'pts';
export const FG_PCT = 'fg%';
export const REBOUNDS = 'reb';
export const ASSISTS = 'ast';
export const STEALS = 'stl';
export const BLOCKS = 'blk';
export const THREEPM = '3pm';
export const PLUSMINUS = '+-';
export const TURNOVERS = 'to';
export const FT_PCT = 'ft%';
export const COST = 'cost';
