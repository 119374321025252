import { Component } from "react";
import {Container, Dropdown } from "react-bootstrap";
import { calculateMatchups } from "../../../utils/stats";
import MatchupTable from './MatchupTable'
import './Matchups.css'

export default class Matchups extends Component {
    constructor(props){
        super(props)
        this.state = {
            selected:this.props.user.uid
        }
        this.stats = calculateMatchups(this.props.game, this.props.players)
    }

    componentDidUpdate(){
        this.stats = calculateMatchups(this.props.game, this.props.players)
    }

    dropdownSelected = (e) => {
        this.setState({ selected: e.target.getAttribute('data') })
    }

    render() {
        if(!this.props.game){
            return <div>Loading...</div>
        }
        var usersInGame = Object.keys(this.props.game.users)
        var dropDownOptions = usersInGame.map(x =>
            <Dropdown.Item data={x} key={x} onClick={this.dropdownSelected}>
                {(x === this.props.user.uid) ? "Your team" : `${this.props.game.users[x].displayName}'s team`}
            </Dropdown.Item>)

        var dropDown = <Dropdown className = "selectTeamDropdown">
            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                Select Team to View
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {dropDownOptions}
            </Dropdown.Menu>
        </Dropdown>
        return <div>
            <Container>
                {dropDown}
                <MatchupTable {...this.props} stats = {this.stats} userId = {this.state.selected}/>
            </Container>
        </div>
    }
}
