import { Component, createRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { withRouter } from '../WithRouter';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from "../Firebase";
import { Form } from 'react-bootstrap';
import moment from "moment-timezone";
import * as CATEGORIES from '../../constants/categories';
import BasketAPI from '../BasketAPI';

class NewGameModal extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            show: false ,
            totalCenters: 1,
            totalUtils: 1,
            totalGuards: 2,
            totalForwards: 2,
            totalCost: 120
        }
        this.dateInput = createRef()
        this.costInput = createRef()
    }

    showModal = async () => {
        this.setState({ show: true })
    }
    hideModal = async () => {
        this.setState({ show: false })
    }

    onSubmit = async event => {
        if(this.dateInput.current.value){
            var currentUser = this.props.firebase.authManager.getCurrentAuthUser()
            var gameId = await this.props.firebase.gameManager.addGame(currentUser, {
                date:this.dateInput.current.value,
                centers: parseInt(this.state.totalCenters),
                forwards: parseInt(this.state.totalForwards),
                guards: parseInt(this.state.totalGuards),
                utils: parseInt(this.state.totalUtils),
                maxCost: parseInt(this.state.totalCost),
                categories: [CATEGORIES.POINTS, CATEGORIES.ASSISTS, CATEGORIES.REBOUNDS, CATEGORIES.FG_PCT,
                     CATEGORIES.FT_PCT, CATEGORIES.STEALS, CATEGORIES.THREEPM, CATEGORIES.TURNOVERS, CATEGORIES.BLOCKS]
            })

            await this.props.firebase.userManager.addGame(currentUser.uid, gameId)
    
            this.props.navigate(ROUTES.GAMES + "/" + gameId)
            this.props.navigate(0)
            this.hideModal()
        }
    }

    centersChanged = async (e) => {
        this.setState({totalCenters:e.target.value})
    }

    guardsChanged = async (e) => {
        this.setState({totalGuards:e.target.value})
    }

    forwardsChanged = async (e) => {
        this.setState({totalForwards:e.target.value})
    }

    utilsChanged = async (e) => {
        this.setState({totalUtils:e.target.value})
    }

    costChanged = async (e) => {
        this.setState({totalCost:e.target.value})
    }



    render() {
        var nyDateString = moment.utc().tz('America/New_York').format("YYYY-MM-DD")
        var totalPlayers = (this.state.totalCenters + this.state.totalGuards + this.state.totalForwards + this.state.totalUtils);
        return (
            <>
                <Button className = "newGameButton" variant="outline-dark" onClick={this.showModal}>
                    New Game
                </Button>

                <Modal show={this.state.show} onHide={this.hideModal} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>New Game</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Date</Form.Label>
                                <Form.Control min = {nyDateString} defaultValue={nyDateString} type="date"  ref = {this.dateInput}/>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Number of Guards</Form.Label>
                                <Form.Control defaultValue = {this.state.totalGuards} min = {0}  max = {10} type="number" onChange = {this.guardsChanged}/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Number of Forwards</Form.Label>
                                <Form.Control defaultValue = {this.state.totalForwards}  min = {0} max = {10} type="number" onChange = {this.forwardsChanged}/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Number of Centers</Form.Label>
                                <Form.Control defaultValue = {this.state.totalCenters} min = {0} max = {10} type="number" onChange = {this.centersChanged}/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Number of Utils</Form.Label>
                                <Form.Control defaultValue = {this.state.totalUtils} min = {0} max = {10} type="number" onChange = {this.utilsChanged}/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{`Total Cost (top 25 are $${ BasketAPI.getNthPlayerCost(25)} to   $${ BasketAPI.getNthPlayerCost(1)})`}</Form.Label>
                                <br />
                                <Form.Control defaultValue = {20 * totalPlayers} min = {0}  type="number" onChange = {this.costChanged} />
                                <Form.Text>{`Suggested: $15-20 per player slot (currently ${totalPlayers})`}</Form.Text>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.hideModal}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={this.onSubmit}>
                            Create
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default withRouter(withFirebase(NewGameModal));