import moment from "moment";

export default class Cache {
    setDisabled = false;

    static async fetchWithCache(func, key, cacheTimeout) {
        var res = Cache.retrieveCache(key, cacheTimeout)
        if (!res) {
            res = await func()
            Cache.cache(key, res)
        }
        return res
    }

    static cache(key, valueObj) {
        Cache.setLocalStorage(key, JSON.stringify(valueObj));
        const currentTime = moment().format('X')
        Cache.setLocalStorage(key + "_timestamp", JSON.stringify({ timestamp: currentTime }));
    }

    static retrieveCache(key, timeOutSeconds) {
        var res = localStorage.getItem(key)
        var cachedTime = localStorage.getItem(key + "_timestamp")
        if (!res) {
            return null
        }
        if (timeOutSeconds) {
            if (!cachedTime) {
                return null
            }
            cachedTime = JSON.parse(cachedTime)
            const currentTime = moment().format('X')
            const timeDiff = currentTime - cachedTime.timestamp
            if (timeDiff >= timeOutSeconds) {
                return null
            }
        }
        return JSON.parse(res)
    }

    static setLocalStorage(param, value) {
        if (this.setDisabled) {
            return
        }
        try {
            localStorage.setItem(param, typeof value == 'object' ? JSON.stringify(value) : value)
            localStorage.setItem('lastStore', new Date().getTime())
        }
        catch (e) {
            if (e.code === 22) {
                // we've hit our local storage limit! lets remove 1/3rd of the entries (hopefully chronologically)
                // and try again... If we fail to remove entries, lets silently give up
                console.log('Local storage capacity reached.')

                var maxLength = localStorage.length
                    , reduceBy = ~~(maxLength / 3);

                for (var i = 0; i < reduceBy; i++) {
                    if (localStorage.key(0)) {
                        localStorage.removeItem(localStorage.key(0));
                    }
                    else break;
                }

                if (localStorage.length < maxLength) {
                    console.log('Cache data reduced to fit new entries. (' + maxLength + ' => ' + localStorage.length + ')');
                    Cache.setLocalStorage(param, value);
                }
                else {
                    console.log('Could not reduce cache size. Removing session cache setting from this instance.');
                    this.setDisabled = true
                }
            }
        }
    }
}