import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './AccountPage.css'

import { AuthUserContext, withAuthorization } from '../Session';
const AccountPage = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <Container>
        <Row>
          <h1>Account Information</h1>
        </Row>
        <Row className = "accountRow">
          <Col className = "accountRowLabel">Display Name</Col>
          <Col className = "accountRowValue">
              {authUser ? authUser.displayName : ""}</Col>
        </Row>
        <Row className = "accountRow">
          <Col className = "accountRowLabel">Email</Col>
          <Col className = "accountRowValue">
              {authUser ? authUser.email : ""}</Col>
        </Row>
        <Row className = "accountRow">
          <Col className = "accountRowLabel">UserID</Col>
          <Col className = "accountRowValue">
              {authUser ? authUser.uid : ""}</Col>
        </Row>
        <Row className = "accountRow">
          <Col className = "accountRowLabel">Version</Col>
          <Col className = "accountRowValue">
              {process.env.REACT_APP_VERSION}</Col>
        </Row>

      </Container>
    )}
  </AuthUserContext.Consumer>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(AccountPage);