import { Component } from "react";
import { Container } from "react-bootstrap";
import BasketAPI from "../BasketAPI";
import { doc, onSnapshot } from "firebase/firestore";
import moment from "moment";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Teams from "./TeamPage/Teams";
import Matchups from "./Matchups/Matchups";
import League from "./League";

export default class AlreadyInGame extends Component {
    constructor(props) {
        super(props)
        this.state = { key: "game" }
    }


    async componentDidMount() {

        const m = moment(this.props.initialGame.config.date, 'YYYY-MM-DD')
        var players = await BasketAPI.GetPlayersOnDate(m.month() + 1, m.date(), m.year())
        var scores = await BasketAPI.GetTeamScores(m.month() + 1, m.date(), m.year())
        this.setState({ players: players, game: this.props.initialGame, scores:scores })

        this.unsub = onSnapshot(doc(this.props.firebase.db, "games", this.props.params.id), (doc) => {
            this.setState({ game: doc.data() })
        });

        this.refreshStats()
    }

    async refreshStats(){
        while(true) {
            const m = moment(this.props.initialGame.config.date, 'YYYY-MM-DD')
            var scores = await BasketAPI.GetTeamScores(m.month() + 1, m.date(), m.year())
            this.setState({ scores:scores })
            await new Promise(r => setTimeout(r, 60 * 1000));
        }
    }



    componentWillUnmount() {
        if (this.unsub) {
            this.unsub()
        }
    }

    setKey = (k) => {
        this.setState({ key: k })
    }

    render() {
        var gameId = this.props.params.id
        var userId = this.props.user.uid
        var players = this.state.players

        if (!(gameId && userId && players)) {
            return <div>Loading</div>
        }

        return <div>
            <Container className="tabsContainer">
                <Tabs
                    id="controlled-tab-example"
                    activeKey={this.state.key}
                    onSelect={this.setKey}
                    className="mb-3"
                >
                    <Tab eventKey="game" title="Matchups">
                        <Matchups {...this.props} game = {this.state.game} players = {this.state.players} scores = {this.state.scores}/>
                    </Tab>
                    <Tab eventKey="team" title="Team">
                        <Teams {...this.props}  game = {this.state.game} players = {this.state.players} scores = {this.state.scores}/>
                    </Tab>
                    <Tab eventKey="league" title="League">
                        <League {...this.props}  game = {this.state.game} players = {this.state.players} scores = {this.state.scores}/>
                    </Tab>
                </Tabs>
            </Container>
        </div>
    }
}
