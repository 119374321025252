import React from 'react';
import { withRouter } from '../WithRouter';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        authUser => {
          if (!condition(authUser)) {
            this.props.navigate(ROUTES.LANDING + "?redirect=" + this.props.location.pathname);
          }
        },
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <Component {...this.props} />
      );
    }
  }

  return withRouter(withFirebase(WithAuthorization));
};

export default withAuthorization;