import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import firebaseConfig from "./firebaseConfig";
import FirebaseAuthManager from "./managers/firebaseAuthManager";
import FirebaseUserManager from "./managers/firebaseUserManager";
import FirebaseGameManager from "./managers/firebaseGameManager";
import {getFirestore} from "firebase/firestore" 

class Firebase {
    constructor() {
        this.app = initializeApp(firebaseConfig);
        this.auth = getAuth(this.app)
        this.db = getFirestore(this.app)
        
        this.authManager = new FirebaseAuthManager(this.auth)
        this.userManager = new FirebaseUserManager(this.db)
        this.gameManager = new FirebaseGameManager(this.db)
    }
}

export default Firebase;