import "firebase/auth";
import { collection, setDoc, doc, arrayUnion, updateDoc, getDoc } from "firebase/firestore";

class FirebaseUserManager {
    constructor(db) {
        this.db = db
    }

    async addUser(user) {
        try {
            const myCollection = collection(this.db, 'users');
            // Define the document reference
            const myDocRef = doc(myCollection, user.uid);

            // Add or update the document
            await setDoc(myDocRef, {...user}, {merge:true});
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }

    async addGame(userId, gameId) {
        const docRef = doc(this.db, "users", userId);

        await updateDoc(docRef, {
            "games": arrayUnion(gameId)
        });
    }

    async getUser(uid) {
        const docRef = doc(this.db, "users", uid);
        return await getDoc(docRef)
    }

}

export default FirebaseUserManager;