import { Component } from "react";
import AddPlayer from "./AddPlayer";
import RemovePlayer from "./RemovePlayer";
import { categoryDisplayValue, extractValueFromStatistics } from "../../../utils/stats";
import * as CATEGORIES from "../../../constants/categories";
import moment from "moment";

export default class TableRow extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    fmtMSS(s) { return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s }

    render() {
        var abbrev = "U"
        switch (this.props.player.position) {
            case "centers":
                abbrev = "C";
                break;
            case "forwards":
                abbrev = "F";
                break;
            case "guards":
                abbrev = "G";
                break;
            default:
                abbrev = "U";
        }

        const players = this.props.players

        const categories = this.props.game.config.categories
        if (this.props.player.id) {
            const currentPlayer = players[this.props.player.id]
            var cols = []
            if (!categories.includes(CATEGORIES.COST)) {
                cols.push(<td className="teamTableRowEntry" key={-1} md={1}>{`$${currentPlayer.statistics.cost}`}</td>)
            }
            for (var i = 0; i < categories.length; i++) {
                var val = 0;
                if (currentPlayer.statistics) {
                    val = extractValueFromStatistics(categories[i], currentPlayer.statistics)
                    val = categoryDisplayValue(categories[i], val)
                }
                cols.push(<td className="teamTableRowEntry" key={i} md={1}>{val}</td>)
            }

            var scoreString = "";

            const timeStart = moment(this.props.scores[currentPlayer.teamId].game.startTimestamp* 1000).format('h:mm A')

            if (this.props.scores[currentPlayer.teamId].game.status.type !== "notstarted") {
                var score1 = this.props.scores[currentPlayer.teamId].game.score.current
                var score2 = this.props.scores[currentPlayer.teamId].game.opponentScore.current
                score1 = score1 ? score1 : 0;
                score2 = score2 ? score2 : 0;

                var game = this.props.scores[currentPlayer.teamId].game
                var status = game.status

                if (status.type === "inprogress") {
                    var quarter = status.description[0]
                    var periodsPlayed = 0;

                    switch (quarter) {
                        case "1":
                            periodsPlayed = 1;
                            quarter = "1st"
                            break
                        case "2":
                            periodsPlayed = 2;
                            quarter = "2nd"
                            break
                        case "3":
                            periodsPlayed = 3;
                            quarter = "3rd"
                            break
                        case "4":
                            periodsPlayed = 4;
                            quarter = "4th"
                            break
                        default:
                            if (status.description === "Pause") {
                                if (game.score && game.score.period1 && game.score.period2 && game.score.period3) {
                                    quarter = "End 3rd"
                                    break
                                }
                                else if (game.score && game.score.period1 && game.score.period2) {
                                    quarter = "Half"
                                    break
                                }
                                else if (game.score && game.score.period1) {
                                    quarter = "End 1st"
                                    break
                                }
                            }

                            quarter = status.description
                    }

                    const timeLeft = this.fmtMSS(periodsPlayed * game.time.periodLength - game.time.played)

                    if (score1 > score2) {
                        scoreString = `${quarter}, ${score1} - ${score2}`
                    }
                    else {
                        scoreString = `${quarter}, ${score1} - ${score2}`
                    }
                    if(periodsPlayed > 0){
                        scoreString = `${timeLeft} ${scoreString}`
                    }
                }
                else {
                    if (score1 > score2) {
                        scoreString = `Final (W): ${score1} - ${score2}`
                    }
                    else {
                        scoreString = `Final (L): ${score1} - ${score2}`
                    }
                }
            }
            else{
                scoreString = `${timeStart}`
            }

            var vs = "@"
            if (this.props.scores[currentPlayer.teamId].game.atHome) {
                vs = "vs."
            }
            scoreString = `${scoreString} ${vs} ${this.props.scores[currentPlayer.teamId].game.opponent.nameCode}`

            var missing = currentPlayer.missing && currentPlayer.missing.type && currentPlayer.missing.reason
            return <tr className="teamPlayerTableRow">
                {<td className="teamTableRowEntry playerOpEntry" md={1}><RemovePlayer disabled={!this.props.allowChange} {...this.props} /></td>}
                <td className="teamTableRowEntry teamTableNameEntry" md={1}>
                    {`${currentPlayer.name}, ${currentPlayer.teamCode} - ${abbrev}`} 
                    {missing? <img alt = "warning" src = "https://cdn-icons-png.flaticon.com/512/4201/4201973.png" style={{"padding-left":"4px", width: "30px"}}></img>:null}
                    <br />
                    {`${scoreString}`}
                </td>
                {cols}
            </tr>
        }

        cols = []
        if (!categories.includes(CATEGORIES.COST)) {
            cols.push(<td className="teamTableRowEntry" key={-1} md={1}>$0</td>)
        }
        for (i = 0; i < categories.length; i++) {
            cols.push(<td className="teamTableRowEntry" key={i} md={1}>0</td>)
        }

        return <tr className="teamPlayerTableRow">
            {<td className="teamTableRowEntry playerOpEntry" md={1}><AddPlayer disabled={!this.props.allowChange}{...this.props} /></td>}
            <td className="teamTableRowEntry" md={1}>{abbrev}</td>
            {cols}
        </tr>
    }
}
