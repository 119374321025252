import { Component } from "react";
import { Button} from "react-bootstrap";
import { withRouter } from "../WithRouter";
import { withFirebase } from '../Firebase';

class SignOutButtonBase extends Component {
    onSubmit = async event => {
        await this.props.firebase.authManager.doSignOut()
    }

    render() {
        return <Button className = "signOutButton" onClick={this.onSubmit}>Sign Out</Button>
    }
}

const SignOutButton = withFirebase(withRouter(SignOutButtonBase))
export default SignOutButton;
