import { Component } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import TableRow from "./TableRow";
import { calculateStatsTotals, categoryDisplayValue } from "../../../utils/stats";
import './Team.css'
import * as CATEGORIES from "../../../constants/categories";

export default class Team extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        var gameId = this.props.params.id
        var userId = this.props.userId
        var game = this.props.game
        var players = this.props.players

        if (!(gameId && userId && players && game)) {
            return <div>Loading</div>
        }

        var playersData = []
        var yourGuards = game.users[userId].team.guards;
        var val = null;
        for (var i = 0; i < parseInt(game.config.guards); i++) {
            val = null;
            if (yourGuards && yourGuards[i]) {
                val = yourGuards[i]
            }
            playersData.push({ id: val, position: "guards", positionIndex: i })
        }
        var yourForwards = game.users[userId].team.forwards;
        for (i = 0; i < parseInt(game.config.forwards); i++) {
            val = null;
            if (yourForwards && yourForwards[i]) {
                val = yourForwards[i]
            }
            playersData.push({ id: val, position: "forwards", positionIndex: i })
        }
        var yourCenters = game.users[userId].team.centers;
        for (i = 0; i < parseInt(game.config.centers); i++) {
            val = null;
            if (yourCenters && yourCenters[i]) {
                val = yourCenters[i]
            }
            playersData.push({ id: val, position: "centers", positionIndex: i })
        }
        var yourUtils = game.users[userId].team.utils;
        for (i = 0; i < parseInt(game.config.utils); i++) {
            val = null;
            if (yourUtils && yourUtils[i]) {
                val = yourUtils[i]
            }
            playersData.push({ id: val, position: "utils", positionIndex: i })
        }

        var totals = calculateStatsTotals(this.props.game, this.props.players)[this.props.userId]
        var totalsCategories = Object.keys(totals);

        var totalUsedCost = totals["cost"]
        var availableCost = game.config.maxCost - totalUsedCost


        var playerRows = [];
        var viewingOwnTeam = this.props.user.uid === this.props.userId

        for (i = 0; i < playersData.length; i++) {
            var allowChange = viewingOwnTeam
            var player = this.props.players[playersData[i].id]

            if (player) {
                const teamId = player.teamId
                const teamInfo = this.props.scores[teamId]

                if (teamInfo && teamInfo.game && teamInfo.game.status && teamInfo.game.status.type !== "notstarted") {
                    allowChange = false
                }
            }
            playerRows.push(<TableRow availableCost={availableCost} allowChange={allowChange} key={i} {...this.props} player={playersData[i]}></TableRow>)
        }


        var headerCols = []
        headerCols.push(<th className="teamTableRowEntry playerOpEntry teamTableRowEntryNoBorder" key={-2} md={1}></th>)
        headerCols.push(<th className="teamTableRowEntry" key={-1} md={1}>Player</th>)
        headerCols.push(<th className="teamTableRowEntry" key={0} md={1}>cost</th>)
        for (i = 0; i < this.props.game.config.categories.length; i++) {
            headerCols.push(<th className="teamTableRowEntry" key={this.props.game.config.categories[i]} md={1}>{this.props.game.config.categories[i]}</th>)
        }

        var totalCols = []
        totalCols.push(<th className="teamTableRowEntry playerOpEntry teamTableRowEntryNoBorder" key={-1} md={1}></th>)
        totalCols.push(<th className="teamTableRowEntry" key={0} md={1}>Total</th>)
        for (i = 0; i < totalsCategories.length; i++) {
            var cat = totalsCategories[i]
            val = categoryDisplayValue(cat, totals[cat])
            if (cat === CATEGORIES.COST) {
                val = `${val}/$${this.props.game.config.maxCost}`;
            }
            totalCols.push(<th className="teamTableRowEntry" key={totalsCategories[i]} md={1}>{val}</th>)
        }

        return <div>
            <Container className="teamContainer">
                <Row>
                    <h1>{(this.props.userId === this.props.user.uid) ? `Your team` : `${this.props.game.users[this.props.userId].displayName}'s team`} </h1>
                    <Table striped bordered responsive >
                        <thead>
                            <tr>
                                {headerCols}
                            </tr>
                        </thead>
                        <tbody>
                            <Col md={2}></Col>
                        </tbody>
                        <tbody>

                            {playerRows}
                        </tbody>
                        <tbody>
                            <tr>
                            {totalCols}
                            </tr>
                        </tbody>
                    </Table>
                </Row>
            </Container>
        </div>
    }
}
