import moment from "moment-timezone";
import basketApiHeaders from "./basketApiConfig";
import playerCosts from "../../data/playerCosts.json"
import Cache from "./cache";

export default class BasketAPI {

    static getNthPlayerCost(n) {
        var vals = Object.values(playerCosts)
        vals.sort((a, b) => b - a)
        return vals[n - 1]
    }

    static async GetTeamScores(month, day, year) {
        const date = month + "-" + day + "-" + year
        const key = "scoresOnDate_" + date
        const res = await Cache.fetchWithCache(async () => (BasketAPI.GetTeamScoresHelper(month, day, year)), key, 60)
        return res
    }

    static async GetTeamScoresHelper(month, day, year) {
        var gamesOnDate = await BasketAPI.GetGamesOnDate(month, day, year)

        var teamIdToObj = {}
        for (let game of gamesOnDate) {
            var id1 = game.homeTeam.id
            var id2 = game.awayTeam.id
            teamIdToObj[id1] = {
                ...game.homeTeam,
                game: {
                    startTimestamp: game.startTimestamp,
                    time: game.time,
                    atHome: true,
                    status: game.status,
                    score: game.homeScore,
                    opponentScore: game.awayScore,
                    opponent: game.awayTeam,
                }
            }
            teamIdToObj[id2] = {
                ...game.awayTeam,
                game: {
                    startTimestamp: game.startTimestamp,
                    time: game.time,
                    atHome: false,
                    status: game.status,
                    score: game.awayScore,
                    opponentScore: game.homeScore,
                    opponent: game.homeTeam,
                }
            }
        }
        return teamIdToObj
    }

    static async GetPlayersOnDate(month, day, year) {
        var date = month + "-" + day + "-" + year
        // get all games on a date
        var gamesOnDateKey = "gamesOnDate_" + date
        var gamesOnDate = await Cache.fetchWithCache(async () => (BasketAPI.GetGamesOnDate(month, day, year)), gamesOnDateKey)

        var allPlayersKey = "allPlayersMap_" + date
        var allPlayersMap = Cache.retrieveCache(allPlayersKey)
        if (!allPlayersMap) {
            // get all teamIds
            var teamIds = gamesOnDate.map(x => x.awayTeam.id).concat(gamesOnDate.map(x => x.homeTeam.id))

            var allPlayers = teamIds.map(x => ({}))

            // iterate through each teamid
            for (var i = 0; i < teamIds.length; i++) {
                // get players for each teamid
                var teamPlayers = await BasketAPI.GetTeamPlayers(teamIds[i])

                allPlayers[i] = teamPlayers.map((x) => {
                    return {
                        name: x.player.name,
                        id: x.player.id.toString(),
                        position: x.player.position,
                        teamCode: x.player.team.nameCode,
                        teamId: x.player.team.id,
                        team: x.player.team.name,
                    }
                })
            }

            allPlayers = allPlayers.flat()
            allPlayersMap = {}

            for (i = 0; i < allPlayers.length; i++) {
                var key = allPlayers[i].id.toString()
                allPlayersMap[key] = allPlayers[i]
            }

            Cache.cache(allPlayersKey, allPlayersMap)
        }



        // get all matchIds
        var matchIds = gamesOnDate.map(x => x.id)

        // update missing players and stats
        var missing = matchIds.map(() => ({}))
        var available = matchIds.map(() => ({}))
        for (i = 0; i < matchIds.length; i++) {
            const matchId = matchIds[i]
            const matchIdKey = `matchIds${matchId}` + date
            var res = await Cache.fetchWithCache(async () => (BasketAPI.GetLivePlayersAndStats(matchId)), matchIdKey, 60)

            missing[i] = res.missing
            available[i] = res.available
        }

        missing = missing.flat()
        available = available.flat()
        for (i = 0; i < missing.length; i++) {
            var m = missing[i]
            var id = m.player.id.toString()
            if (allPlayersMap[id]) {
                allPlayersMap[id].missing = {
                    type: m.type,
                    reason: m.reason
                }
            }
        }

        for (i = 0; i < available.length; i++) {
            m = available[i]
            id = m.player.id.toString()
            if (allPlayersMap[id]) {
                allPlayersMap[id].statistics = m.statistics
            }
        }
        for (let [id] of Object.entries(allPlayersMap)) {
            if (!allPlayersMap[id].statistics) {
                allPlayersMap[id].statistics = {}
            }
            allPlayersMap[id].statistics.cost = playerCosts[id]
        }
        return allPlayersMap
    }

    static async GetTeamPlayers(teamId) {
        const url = 'https://basketapi1.p.rapidapi.com/api/basketball/team/' + teamId + '/players';
        const options = {
            method: 'GET',
            headers: basketApiHeaders
        };

        try {
            const response = await fetch(url, options);
            const result = await response.json();

            return result.players;
        } catch (error) {
            console.error(error);
        }
    }

    static async GetLivePlayersAndStats(matchId) {
        const url = 'https://basketapi1.p.rapidapi.com/api/basketball/match/' + matchId + '/lineups';
        const options = {
            method: 'GET',
            headers: basketApiHeaders
        };

        try {
            const response = await fetch(url, options);
            const result = await response.json();

            if (!result.away.missingPlayers) {
                result.away.missingPlayers = []
            }
            if (!result.home.missingPlayers) {
                result.home.missingPlayers = []
            }
            var allMissingPlayers = result.away.missingPlayers.concat(result.home.missingPlayers);
            var allAvailablePlayers = result.away.players.concat(result.home.players);

            return { missing: allMissingPlayers, available: allAvailablePlayers };
        } catch (error) {
        }

        return { missing: [], available: [] };
    }

    static async GetGamesOnDate(month, day, year) {
        const url = 'https://basketapi1.p.rapidapi.com/api/basketball/matches/' + day + '/' + month + '/' + year;
        const options = {
            method: 'GET',
            headers: basketApiHeaders
        };

        try {
            const response = await fetch(url, options);
            const result = await response.json();
            var res = result.events.filter(x => x.tournament && x.tournament.slug === "nba")
            res = res.filter((x) => {
                var parsedTime = moment.utc(x.startTimestamp * 1000).tz('America/New_York')
                return parsedTime.month() + 1 === month && parsedTime.date() === day
            })

            res = res.map((x) => {
                return {
                    ...x
                }
            })

            return res;
        } catch (error) {
            console.error(error);
        }
    }
}