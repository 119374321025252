

import { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col } from "react-bootstrap";
import { withRouter } from '../../WithRouter';
import { withFirebase } from "../../Firebase";

class AddPlayer extends Component {
    constructor(props) {
        super(props)
        this.state = { show: false }
    }

    showModal = async () => {
        this.setState({ show: true })
    }
    hideModal = async () => {
        this.setState({ show: false })
    }


    onClick = async event => {
        var playerId = event.target.value
        var gameId = this.props.params.id
        var userId = this.props.user.uid
        this.props.firebase.gameManager.addPlayerToTeam(gameId, userId, playerId, this.props.player.position, this.props.player.positionIndex)
        this.hideModal()
    }


    render() {
        if (!this.props.players) {
            return <div>Loading...</div>
        }

        var teamObj = this.props.game.users[this.props.user.uid].team
        var yourTeam = Object.values(teamObj.centers).concat(Object.values(teamObj.forwards)).concat(Object.values(teamObj.utils)).concat(Object.values(teamObj.guards))

        var playerArr = Object.values(this.props.players)

        var canChoosePlayers = playerArr.filter(x => (!yourTeam.includes(x.id)))

        if (this.props.player.position === "centers") {
            canChoosePlayers = canChoosePlayers.filter(x => x.position.includes("C"))
        }
        else if (this.props.player.position === "forwards") {
            canChoosePlayers = canChoosePlayers.filter(x => x.position.includes("F"))
        }
        else if (this.props.player.position === "guards") {
            canChoosePlayers = canChoosePlayers.filter(x => x.position.includes("G"))
        }

        canChoosePlayers = canChoosePlayers.filter(x => {
            const teamId = x.teamId
            const teamInfo = this.props.scores[teamId]
            if (teamInfo && teamInfo.game && teamInfo.game.status && teamInfo.game.status.type !== "notstarted") {
                return false
            }
            return true
        })

        canChoosePlayers = canChoosePlayers.filter(x => x.statistics.cost <= this.props.availableCost)

        canChoosePlayers.sort(function (a, b) { return b.statistics.cost - a.statistics.cost; });

        var playerSelect = canChoosePlayers.map(x => {
            var missing = x.missing && x.missing.type && x.missing.reason
            return <Row className="addPlayerRow" key={x.id}>
                <Col className="addPlayerButtonCol">
                    <Button variant="outline-success" onClick={this.onClick} value={x.id}>+</Button>
                </Col>
                <Col className="addPlayerTableEntry addPlayerCostCol">
                    ${x.statistics.cost}
                </Col>
                <Col className="addPlayerTableEntry">
                    <>
                        <Row className = "playerTableEntryName">
                            {`${x.teamCode}: ${x.name} (${x.position.split("").join(",")})`}
                            {missing ? <img src="https://cdn-icons-png.flaticon.com/512/4201/4201973.png" alt = "warning" style={{ "padding-left": "4px", width: "40px" }}></img> : null}

                        </Row>
                    </>
                </Col>
            </Row>
        })

        return (
            <>
                <Button variant="success" disabled={this.props.disabled} onClick={this.showModal}>
                    +
                </Button>

                <Modal show={this.state.show} onHide={this.hideModal} className="addPlayerModal" size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Add Player</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col sm={4}>
                                    <p>
                                        Remaining funds:</p>
                                </Col>
                                <Col>
                                    ${this.props.availableCost}
                                </Col>
                            </Row>
                            <Row>
                                {playerSelect.length > 0 ? playerSelect : <Col>No players available (you cannot add or remove players that are playing already)</Col>}
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.hideModal}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default withRouter(withFirebase(AddPlayer));