import { Component } from "react";
import { Container, Row } from "react-bootstrap";
export default class GameNotFound extends Component {
    render() {
        return <div>
            <Container>
                <Row>
                    game {this.props.params.id} not found
                </Row>
            </Container>
        </div>
    }
}
